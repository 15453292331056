/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

//
// Load core variables and mixins
// --------------------------------------------------
@import "variables.less";
@import "mixins.less";
@import (reference) "glyphicons.less";
//
// ads-COM Custom vars
// -------------------------------------------------
@fonts-path : "../../fonts";
@images-path : "../../images";
@images-path-old : "../../images/old";
@transition-time: 0.75s;
@transition-time-short: 0.5s;
@transition-time-shorter: 0.25s;
@transition-time-long: 1s;
//
// Import the fonts
// -------------------------------------------------
@import 'https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:100,300,400';
//
// Buttons
// --------------------------------------------------
// Common styles
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
    padding: @padding-base-vertical (@padding-large-horizontal*2);
    text-transform: uppercase;
    border: transparent;
    white-space: normal;
    // Reset the shadow
    &:active,
    &.active {}
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        .box-shadow(none);
    }
    .badge {
        text-shadow: none;
    }
}
.form-control{
    border-top: none;
    border-left: none;
    border-right: none;
}
// Mixin for generating new styles
.btn-styles(@btn-color: #555) {
    &:hover,
    &:focus {
        background-color: #fff;
        color: @btn-color;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
        transform: translateY(-5px);
    }
    &:active,
    &.active {
        background-color: darken(@btn-color, 12%);
        border-color: darken(@btn-color, 14%);
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: darken(@btn-color, 12%);
            background-image: none;
        }
    }
}
.setTitleStyle(@color, @bg) {
    position: relative;
    z-index: 20;
    margin: (@padding-large-horizontal*2) 0;
    font-family: @headings-font-family;
    text-align: center;
    @media(min-width: @screen-sm-min) {
        &:before {
            content: "";
            border-top: 1px solid @color;
            position: absolute;
            width: 100%;
            left: 0;
            top: 50%;
            z-index: 1;
        }
        span {
            background-color: @bg;
            position: relative;
            z-index: 2;
            padding: 0 @padding-large-horizontal;
            display: inline-block;
        }
    }
}

.setZoomImg(@scale) {
    overflow: hidden;
    img {
        transition: @transition-time;
    }
    &:hover {
        img {
            transform: scale(@scale) rotate(-1deg);
        }
    }
}

// Common styles
.btn {
    // Remove the gradient for the pressed/active state
    &:active,
    &.active {
        background-image: none;
    }
}

.thumbnail {
    border: none;
    margin-bottom: 0;
    position: relative;
    &:before {
        content: "";
        box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.5) inset;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        height: 100%;
    }
}

// Apply the mixin to the buttons
.btn-default {
    .btn-styles(@btn-default-bg);
}

.btn-primary {
    .btn-styles(@btn-primary-bg);
}

.btn-success {
    .btn-styles(@btn-success-bg);
}

.btn-info {
    .btn-styles(@btn-info-bg);
}

.btn-warning {
    .btn-styles(@btn-warning-bg);
}

.btn-danger {
    .btn-styles(@btn-danger-bg);
}

// Table
.table {
    box-shadow: 0 0 50px 5px #000;
    th {
        color: #fff;
        background-color: @brand-primary;
        text-align: center;
        text-transform: uppercase;
        &:last-child {
            border-right: none;
        }
    }
    tbody{
        border:3px solid @brand-primary;
        background: #fff;
    }
    td {
        font-weight:500;
        &:last-child {
            border-right: none;
        }
    }
    th.empty{
        background: #000;
    }
}

.container {
    @media (min-width: @screen-xl-min) {
        width: 1400px;
    }
}

.clear {
    .clearfix();
}

// base html Markup
a,
.btn {
    transition: @transition-time-shorter;
}

body {
    @media(min-width:@screen-md-min){
        transition: background-position 1.5s, opacity @transition-time-short ;
        background-image: url('@{images-path}/body-bg.jpg');
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center 0;
        background-attachment: fixed;
        }
}

@keyframes animBody {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

// ads-COM COL 20%
.col-md-20 {
    @media(min-width: @screen-md-min) {
        width: 20%;
        float: left;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

// No Gutters-style
.row.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
}

// Nav //
.navbar-header{
    position: relative;
    transition: @transition-time-shorter;
    &.active{
        background-color: @gray;
    }

}
ul#menuReseauSociaux {
    position: absolute;
    right: 0;
}
.facebook,
.twitter,
.insta {
    width: 30px;
    height: 30px;
   margin-top: 15px;
    display: inline-block;
    overflow: hidden;
    text-indent: -10000px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    margin-right: 5px;
    @media(min-width: @screen-sm-min) {
     margin-right: (@padding-base-horizontal);
    }
    border-radius: 100%;
    &:hover {
        background-color: @brand-primary;
        color: @brand-primary;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
        transform: translateY(-5px);
    }
}
.facebook {
    background-image: url('@{images-path}/header/icon-facebook.png');
}
.twitter {
    background-image: url('@{images-path}/header/icon-twitter.png');
}
.insta {
    background-image: url('@{images-path}/header/icon-insta.png');
}
.navbar.navbar-default {
    .phone {
        color: #fff;
        height: 35px;
        text-transform: uppercase;
        border-radius: 30px;
        position: relative;
        left: -3px;
        padding: 5px 22px 8px 37px;
    @media(min-width: @screen-sm-min) {
            padding: 8px 22px 8px 42px;
    }
        
        margin-top: 12px;
        font-size: 16px;
        &:before {
            content: "";
            background: url('@{images-path}/header/icon-phone.png') 0 0 no-repeat transparent;
            background-size: 100% auto;
            border-radius: 100%;
            display: inline-block;
            width: 30px;
            height: 30px;
            left: 3px;
            top: 3px;
            position: absolute;
        }
        &:hover {
            background-color: @brand-primary;
            color: #fff;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
            transform: translateY(-5px);
        }
    }
    .navbar-toggle {
        display: block;
        border: none;
        font-size: 11px;
        border-radius: 0;
        margin: 0;
        padding: 16px;
        transition: @transition-time-short;
        height: 63px;
        float: left;
        .toggle-title {
            text-transform: uppercase;
            font-family: @font-family-base;
            margin-bottom: 2px;
            display: block;
            transition: @transition-time-short;
        }
        .icon-bar {
            transition: @transition-time-short;
        }
        .icon-bar.bar-1 {
            width: 100%;
        }
        &:hover{
            background-color: @gray;
        }
    }
}
.navbar-header.active .navbar-toggle, .navbar-header.active .navbar-toggle:hover{
            background-color: #fff;
        .toggle-title {
            margin-top: -30px;
            opacity: 0;
    }
        .icon-bar.bar-1 {
            transform: translateX(-1000px);
        }
        .icon-bar.bar-2 {
            transform: rotate(45deg) translateX(4px) translateY(4px);
            width: 100%;
            background-color: #000;
        }
        .icon-bar.bar-3 {
            transform: rotate(-45deg);
            width: 100%;
            background-color: #000;
        }
    
}

.setAnimMenu(@decalage) {
    @transition-decalage: 0.125s;
    transition: opacity @transition-time-short (0.25s + (@transition-decalage * @decalage)), transform @transition-time-shorter (0.25s + (@transition-decalage * @decalage));
}

.navbar-default .navbar-collapse {
    background: lighten(@brand-secondary, 10%);
    @media(min-width: @screen-lg-min) {
        position: absolute;
        left: 60px;
        font-size: 18px;
        z-index: @zindex-navbar-fixed;
        transform: translatey(-50px) scaleY(2);
        opacity: 0;
        background:transparent;
        float: left;
    }
}

.nav.navbar-nav {
    @media(min-width: @screen-lg-min) {
        background: @gray;
        transition: @transition-time-short;
        transition-delay: 0.125s;
        margin: 0;
        right: (@grid-gutter-width);
        padding: @padding-large-horizontal (@padding-large-vertical*2);
        top: 0;
        padding: 0;
    }
        overflow: hidden;
    & > li {
        opacity: 0;
        transform: translateY(-30px) ;
        transform-origin: center top;
        overflow: hidden;
        @media(min-width: @screen-lg-min) {
            float: left;
        }
        &:last-child a{
            border: none;
        }
    }
    & > li > a {
        border-right: 1px solid fade(#fff, 25%);
        padding-top: 19px;
        padding-bottom: 19px;
        transition: @transition-time, opacity @transition-time @transition-time-long;
        @media(max-width: @screen-lg-max) {
        padding-left: 7px;
        padding-right: 7px;
            font-size: 16px;
        
        }
    }
    & > li.active > a {
        border-bottom: 1px solid fade(#fff, 0);
    }
    & > li:nth-child(1) {
        .setAnimMenu(0);
    }
    & > li:nth-child(2) {
        .setAnimMenu(2);
    }
    & > li:nth-child(3) {
        .setAnimMenu(3);
    }
    & > li:nth-child(4) {
        .setAnimMenu(4);
    }
    & > li:nth-child(5) {
        .setAnimMenu(5);
    }
    & > li:nth-child(6) {
        .setAnimMenu(6);
    }
    & > li:nth-child(7) {
        .setAnimMenu(7);
    }
    & > li:nth-child(8) {
        .setAnimMenu(8);
    }
    & > li:nth-child(9) {
        .setAnimMenu(2);
    }
    & > li:nth-child(9) {
        .setAnimMenu(9);
    }
    & > li:nth-child(10) {
        .setAnimMenu(10);
    }
    & > li:nth-child(11) {
        .setAnimMenu(11);
    }
    & > li.open > a {
        border-bottom: 1px solid transparent;
        font-weight: 800;
    }
    & > li.open > ul {
        margin-top: 0;
        padding-top: 0;
    }
    & > li .open > a,
    & > li .open > a:hover {
        border: none;
    }
    & > li.open > ul > li > a {
        background-color: @navbar-default-link-active-bg;
        padding: 0 @padding-large-horizontal (@padding-large-horizontal*4);
    }
    .dropdown-menu {
        opacity: 0;
        height: 0;
        position: static;
        transform: translateY(10000px) scaleY(0);
        overflow: hidden;
        border-bottom: 1px solid fade(#fff, 50%);
    }
    .open > .dropdown-menu {
        transform: translateY(0);
        opacity: 1;
        height: auto;
        transition: opacity @transition-time, height @transition-time;
        padding-bottom: 20px;
        background-color: @navbar-default-link-active-bg;
    }
    .dropdown-menu li a {
        padding-top: 10px;
        padding-bottom: 10px;
        transform: translateX(-20px);
    }
    & > li.open > .dropdown-menu > li > a {
        padding-top: 10px;
        padding-bottom: 10px;
        white-space: normal;
        transform: none;
        background-color: @navbar-default-link-active-bg;
        padding: @padding-base-vertical @padding-large-horizontal @padding-large-vertical (@padding-large-horizontal*4);
    }
}

li.close-menu {
    margin-bottom: 50px;
}

.navbar-collapse.collapsing {
    .navbar-nav > li {
        opacity: 0;
    }
    width: 100%;
}

.navbar-collapse.in {
    transform: translateY(0);
    opacity: 1;
    @media(max-width : @screen-md-max){
    clear: both;
        
    }
    .navbar-nav {
        transform: translateX(0);
        & > li {
            opacity: 1;
            transform: none;
        }
    }
}

.match-classement-calendrier {
    #gradient.vertical( lighten(@brand-secondary,
    10%);
    #000;
    0%;
    60%);
}
.jumbotron {
    text-shadow: 0 0 40px rgba(0,0,0,1);
     p {
    font-weight: 300;
}
    
}
.navbar-brand {
    float: none;
    background-image: url('@{images-path}/header/logo-crossfit-orion.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 250px;
    height: 370px;
    display: block;
    margin: auto;
	@media(max-width : @screen-xs-max){
		width: 125px;
		height: 185px;
	}
}

h1,
h2,
h3,
h4,
h5 {
    strong {
        font-family: @font-family-serif;
    }
}
h1{
	@media(max-width : @screen-sm-max){
		font-size:@font-size-h2;
	}
}
.title {
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    background-size: 100% auto;
    background-repeat: no-repeat;
    margin: auto;
    margin-bottom: 20px;
    width: 150px;
    height: 170px;
    position: relative;
    z-index: 10;
    strong {
        font-size: 20px;
    }
}

.resa {
    font-size: @font-size-h3;
    margin-top: 200px;
    text-align: center;
    font-weight: 100;
    .container {
        background-image: url('@{images-path}/home/planning-bg.png');
        border-top: 1px solid #fff;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 100% auto;
    }
    .title {
        background-image: url('@{images-path}/home/planning-title.png');
        margin-top: -90px;
    }
    ul {
        margin-top: @padding-large-vertical * 2;
        margin-bottom: @padding-large-vertical * 4;
    }
    .iframe {
        background-color: #fff;
        background-image: url('@{images-path}/home/resa-bg.jpg');
        border-top: 1px solid #fff;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 100% auto;
        padding-top: @padding-large-vertical * 4;
        padding-bottom: @padding-large-vertical * 6;
        width: 100%;
        overflow: auto;
    }
}
.internal .resa {
    margin-top: 0;
    .container {
        border: none;
        background: none;
    }
    .title{
        display: none;
    }
}

.tarifs {
    padding-bottom: 200px;
    font-weight: 100;
    color: black;
    background-image: url('@{images-path}/home/tarif-bg.jpg');
    background-position: left top;
    background-repeat: no-repeat;
    background-color: #000;
    
        @media(min-width:@screen-md-min){
    padding-top: 240px;
    }
    .container {
        border-top: 1px solid #fff;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 100% auto;
    }
    .title {
        background-image: url('@{images-path}/home/tarif-title.png');
        @media(min-width:@screen-md-min){
        margin-top: -325px;
            }
        margin-bottom: 50px;
    }
}
.internal .tarifs {
    background: none;
      
    @media(min-width:@screen-md-min){
    margin-top: 75px;
    padding-top: 50px;
    }  
    .title {
        display: none;
    }
    .container-tarif{
        @media(min-width:@screen-md-min){
            left: 30%;
        }
    }
}
.follow{
    position: relative;
    font-size: @font-size-h3;
    font-weight: 100;
    text-align: center;
    padding-bottom: @padding-large-vertical * 6;
    background-color: rgba(0,0,0,0.85);
    margin-top: 100px;
    .title {
        background-image: url('@{images-path}/home/nous-suivre-title.png');

        margin-top: 75px;

    }
    .container{
    border-top: 1px solid fade(#fff, 25%);
    }
    .facebook, .twitter, .insta{
        width:47px;
        height: 47px;
    }
    .facebook {
        background-image: url('@{images-path}/header/icon-facebook-xl.png');
    }
    .twitter {
        background-image: url('@{images-path}/header/icon-twitter-xl.png');
    }
    .insta {
        background-image: url('@{images-path}/header/icon-insta-xl.png');
    }
    
}
.footer{
        background-image: url('@{images-path}/footer/map.jpg');
        border-top: 1px solid #fff;
        background-repeat: no-repeat;
        background-position: top center;
    background-color: #000;
    padding-top:350px;
    .adresse{
        h2{
        background-image: url('@{images-path}/footer/pin.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 20px auto;
            padding-left: 30px;
            margin-top: 0;
            
        }
        
    }
    .horaire{
            padding-top: @padding-large-vertical;
        h2{
        background-image: url('@{images-path}/footer/calendar.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 20px auto;
            padding-left: 30px;
            margin-bottom: @padding-large-vertical * 2;
            
        }
        .row{
        border-top: 1px solid @gray-light;
            padding-top: @padding-large-vertical;
            padding-bottom: @padding-large-vertical;
            
        }
        .bordered{
        border-left: 1px solid @gray-light;
            
        }
        
    }
    .contact{
        	margin-top: @padding-large-horizontal * 2;
         @media (min-width: @screen-md-min) {
        	padding-left: @padding-large-horizontal * 2;
			margin-top:0;
		}
        h2{
        background-image: url('@{images-path}/footer/send.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 20px auto;
            margin-top: 0;
            padding-left: 30px;
            margin-bottom: @padding-large-vertical * 2;
            
        }
          @media (min-width: @screen-sm-min) {
    .control-label {
      text-align: left;
      margin-bottom: 0;
      padding-top: (@padding-base-vertical + 1); // Default padding plus a border
    }
              .btn{
                  width: 100%;
              }
  }
        
    }
}
.external-link{
    background: #fff;
            margin-top: @padding-large-vertical * 2;
}

.internal .content{ 
    margin-bottom: 200px;
        background-image: url('@{images-path}/home/planning-bg.png');
        border-top: 1px solid #fff;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: 120% 100%;
    padding-top: @padding-large-vertical * 4;
    text-shadow: 0 0 40px rgba(0,0,0,1);
    
    p {
      margin: 0 0 (@line-height-computed);
    }
}

#back-to-top{
    background: url('@{images-path}/arrow.png') center center no-repeat @brand-secondary;
    background-size: 40px 40px;
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: @padding-large-horizontal;
    right: @padding-large-horizontal;
    transform: rotate(90deg) translateX(100px);
    border-radius: 100%;
    opacity: 0;
    transition: @transition-time-short;
    cursor:pointer;
    z-index: 2;
    &.show{
        opacity: 1;
        transform: rotate(-90deg);
    }
}
.text-dark{
    color: @gray-darker;
}
.legal {
    font-size: @font-size-small;
    margin-top: 5px;
}